<template>
<section class="showcase" v-if="featuredpackages">
    <div class="container">
        <div class="headline">
            <h2 class="headline__title">Awesome <span>Package</span></h2>
            <span class="headline__meta">Pack and Go</span>
        </div>
        <div class="row">
            <div class="col xs-12 sm-6 md-3" v-for="(featuredpackage,index) in featuredpackages" :key="index">
                <article class="package">
                    <div class="package__img">
                        <router-link :to="'/package/'+featuredpackage.slug"><img :src="urlPath+'uploads/media/package/thumbnail/326245_'+featuredpackage.featuredimage" alt="image description"></router-link>
                        <span v-if="featuredpackage.packagestatus=='Private'" class="tag tag-danger">{{ featuredpackage.packagestatus }}</span>
                        <span v-if="featuredpackage.packagestatus=='Limited'" class="tag tag-warning">{{ featuredpackage.packagestatus }}</span>
                        <span v-if="featuredpackage.packagestatus=='Guaranteed'" class="tag tag-success">{{ featuredpackage.packagestatus }}</span>
                    </div>
                    <div class="package__desc">
                        <div class="package__desc-group">
                            <span class="package__desc-price" v-if="featuredpackage.discountedprice"><sup>US$</sup><strong>{{featuredpackage.discountedprice}}</strong></span>
                            <span class="package__desc-price" v-else><sup>US$</sup><strong>{{featuredpackage.regularprice}}</strong></span>
                            <span class="package__desc-duration">{{ featuredpackage.tripdays }} days</span>
                        </div>
                        <h3 class="package__desc-title" data-mh="awesometitle">
                            <router-link :to="'/package/'+featuredpackage.slug" >{{featuredpackage.title}}</router-link>
                        </h3>
                        <span class="package__desc-activity" >Activities: {{featuredpackage.highlightvalue}}</span>
                    </div>
                </article>
            </div>
            
        </div>
    </div>
</section>
</template>

<script>
import axios from 'axios';
import jQuery from 'jquery';
export default {
    name: 'Awesomepackage',
    data() {
        return {
            featuredpackages: undefined,
            apiPath:process.env.VUE_APP_API,
            urlPath:process.env.VUE_APP_PATH,
        }
    },
    async mounted() {
        this.featuredPackage();
    },
    methods: {
        featuredPackage() {
            axios.get(this.apiPath+'featuredpackage')
                .then((response) => {
                    this.featuredpackages = response.data;
                })
        }
    },
    async updated(){
        jQuery('.showcase .package__desc-title').matchHeight();
    }
}
</script>
